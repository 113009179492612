<template>
    <div>
        <BigHeader>
            <template v-slot:title>
                <h1>Ошибка 404</h1>
                <p>
                    Проверьте адресную строку, возможно вы ошиблись при вводе данных
                </p>
                <base-button v-if="isSbg" link to="/" mode="yellow" style="margin-top: 24px">На главную</base-button>
            </template>
        </BigHeader>
    </div>
</template>

<script>
import BigHeader from '@/components/common/big-header/BigHeader'

import sbg from '@/mixins/sbg'

export default {
    name: 'NotFound',
    mixins: [
        sbg
    ],
    components: {
        BigHeader,
    }
}
</script>